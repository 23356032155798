<template>
  <v-app>
    <v-app-bar app color="rgb(173 173 181)">
      <div class="mx-auto" style="max-width: 1440px; width: 100%">
        <h6 class="text-h6">Allstate Life Referral Form</h6>
      </div>
    </v-app-bar>
    <v-main>
      <v-img v-resize="onResize" :src="heroImg" :width="windowSize.x" :height="windowSize.y" cover>
        <v-container class="fill-height" fluid>
          <div class="mx-auto" style="height: 620px; width: 600px">
            <v-card width="600" height="520" class="px-8 py-2" color="">
              <v-card-title class="justify-center text-h5"> Lead Capture Form </v-card-title>

              <v-card-text class="mt-4">
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-text-field
                    v-model="formData.FirstName"
                    label="First name"
                    outlined
                    :rules="rules.firstName"
                  >
                  </v-text-field>

                  <v-text-field
                    v-model="formData.LastName"
                    label="Last name"
                    outlined
                    :rules="rules.lastName"
                  >
                  </v-text-field>

                  <v-select
                    v-model="formData.State"
                    label="State"
                    outlined
                    :items="stateItems"
                    required
                  >
                  </v-select>

                  <v-text-field
                    v-model="formData.PhoneNumber"
                    label="Phone number"
                    v-mask="'(###) ###-####'"
                    outlined
                    :rules="rules.phoneNumber"
                  >
                  </v-text-field>

                  <!-- <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formData.DOB"
                        label="Date of birth"
                        append-icon="mdi-calendar"
                        outlined
                        autocomplete="off"
                        persistent-hint
                        hint="YYYY-MM-DD format"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="formData.DOB" @input="menu = false" scrollable no-title>
                    </v-date-picker>
                  </v-menu> -->
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-btn @click="clearForm">Clear form</v-btn>

                <v-spacer></v-spacer>

                <v-btn
                  color="primary"
                  class="px-6"
                  @click="submitHandler"
                  :loading="loading"
                  :disabled="loading || disabled"
                  >Submit</v-btn
                >
              </v-card-actions>
            </v-card>

            <v-alert
              :value="alert"
              type="success"
              dark
              dismissible
              class="mt-4"
              border="top"
              transition="scale-transition"
            >
              Success! Lead information has been submitted.
            </v-alert>

            <v-alert
              :value="errorAlert"
              type="error"
              dark
              dismissible
              class="mt-4"
              border="top"
              transition="scale-transition"
            >
              There was a problem submitting. Please try again. If you continue to experience
              problems, please contact the site administrator.
            </v-alert>
          </div>
        </v-container>
      </v-img>
    </v-main>
  </v-app>
</template>

<script>
import heroImg from '@/assets/hero-img.jpg'
//import axios from 'axios'

export default {
  name: 'App',
  components: {},
  mounted() {
    this.onResize()
  },
  data: () => ({
    heroImg,
    valid: true,
    //menu: false,
    alert: false,
    errorAlert: false,
    loading: false,
    disabled: false,
    stateItems: [
      'Alabama',
      'Alaska',
      'American Samoa',
      'Arizona',
      'Arkansas',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware',
      'District of Columbia',
      'Federated States of Micronesia',
      'Florida',
      'Georgia',
      'Guam',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Iowa',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Maine',
      'Marshall Islands',
      'Maryland',
      'Massachusetts',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Montana',
      'Nebraska',
      'Nevada',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'New York',
      'North Carolina',
      'North Dakota',
      'Northern Mariana Islands',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Palau',
      'Pennsylvania',
      'Puerto Rico',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont',
      'Virgin Island',
      'Virginia',
      'Washington',
      'West Virginia',
      'Wisconsin',
      'Wyoming',
    ],
    formData: {
      FirstName: '',
      LastName: '',
      State: '',
      PhoneNumber: '',
      // DOB: '',
    },
    formDataCleared: {
      FirstName: '',
      LastName: '',
      State: '',
      PhoneNumber: '',
      // DOB: '',
      //new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
    },
    windowSize: {
      x: 0,
      y: 0,
    },
    rules: {
      firstName: [
        (v) => !!v || 'First name is required,',
        (v) => v.length <= 32 || 'First name must be less than 32 characters.',
      ],
      lastName: [
        (v) => !!v || 'Last name is required,',
        (v) => v.length <= 32 || 'Last name must be less than 32 characters.',
      ],
      State: [
        (v) => !!v || 'Zip code is required.',
        (v) => v.length === 5 || /^\d{5}/.test(v) || 'Zip code must be exactly 5 digits.',
      ],
      phoneNumber: [
        (v) => !!v || 'Phone number is required.',
        (v) => v.length === 14 || 'Phone number must be 10 digits.',
      ],
    },
  }),
  methods: {
    onResize() {
      this.windowSize = {
        x: window.innerWidth,
        y: window.innerHeight - this.$vuetify.application.top,
      }
    },
    async submitHandler() {},
    clearForm() {
      this.formData = this.formDataCleared
      this.disabled = false
      this.errorAlert = false
      this.alert = false
    },
  },
  computed: {},
}
</script>

<style lang="scss"></style>
